import React from "react";
import { Box } from "@src/components/Boxes";
import Container from "@src/components/Container";
import BlogPageTitle from "@src/components/blog/BlogPageTitle";
import BlogArticle from "@src/components/blog/BlogArticle";
import NextPrevNav from "@src/components/blog/NextPrevNav";
import BlogSEO from "@src/components/blog/BlogSEO";
import { PageProps } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { IGatsbyImageData } from "gatsby-plugin-image";

export type PostType = {
  path: string;
  title: string;
  publish_date: string;
  author: {
    bio: string;
    name: string;
    slug: string;
    github_nick?: string;
    image: {
      filename_disk: string;
      file: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData;
        };
      };
    };
  };
  image: {
    width: number;
    filename_disk: string;
    file: {
      publicURL: string;
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
  body: string;
  slug: string;
  categories?: {
    blog_category: {
      name: string;
      slug: string;
    };
  }[];
  hasExcerpt: boolean;
  node: never;
};
export type Post = PostType | { node: PostType };

const AuthorRoute = ({
  pageContext,
}: PageProps<
  any,
  { items: Post[]; pagePath: string; name: string; previousPagePath: string; nextPagePath: string }
>) => {
  const { previousPagePath, nextPagePath, items, name, pagePath } = pageContext;

  return (
    <Box backgroundColor="#f5f7f9" overflow="auto">
      <BlogSEO title={`${name}, Author at The ClassDojo Blog`} slug={pagePath} type="object" />
      <Box backgroundColor="#f5f7f9" paddingTop={["90px", "95px"]}>
        <Container>
          <BlogPageTitle text={`Posts By: ${name}`} />
          <Box width={["100%", "100%", 10 / 12, 8 / 12]} mx="auto" px={[0, "15px"]}>
            {items.map((post, index) => (
              <BlogArticle key={`postpreview-${index}`} post={post} showExcerpt />
            ))}
            <NextPrevNav nextLink={nextPagePath} prevLink={previousPagePath} />
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default AuthorRoute;
